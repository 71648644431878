import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "../../comp/Header";
import NewFooter from "../../comp/NewFooter";

const FileManagerScreen = () => {
  const getJsson = () =>
    JSON.stringify({
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Home",
          item: "https://rareprob.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Rareprob Apps",
          item: "https://rareprob.com/apps",
        },
        {
          "@type": "ListItem",
          position: 3,
          name: " File Manager",
          item: "https://rareprob.com/apps/file-manager-app",
        },
      ],
    });

  const getJson = () =>
    JSON.stringify({
      "@context": "https://schema.org",
      "@type": "SoftwareApplication",
      url: "https://rareprob.com/apps/file-manager-app",
      downloadUrl:
        "https://play.google.com/store/apps/details?id=com.rocks.music.videoplayer",
      name: "File Manager App | Best File Manager App for Android | Rareprob",
      description:
        "Download the best file manager app for Android that provides a user interface to manage files and folders. A powerful file manager app easily view images, audio, videos, supports SD cards",
      operatingSystem: "Android",
      applicationCategory: "File Manager",
      applicationSubCategory: "Tools, File Explorer",
      image:
        "https://rareprob.com/images/fileManagerScreen/rare-fileMangerTopMobile-min.webp",
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: 4.8,
        bestRating: 5,
        ratingCount: 79,
      },
      offers: {
        "@type": "Offer",
        price: 0,
        priceCurrency: "USD",
      },
    });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          File Manager App | Best File Manager App for Android | Rareprob
        </title>

        <meta
          name="description"
          content="Download the best file manager app for Android that provides a user interface to manage files and folders. A powerful file manager app easily view images, audio, videos, supports SD cards"
        />
        <script type="application/ld+json">{getJson()}</script>
        <script type="application/ld+json">{getJsson()}</script>
      </Helmet>
      <nav>
        <Header color="bg-purple-900" page="app" />
      </nav>
      <main className="bg-gradient-to-b from-[#66369C] via-[#442e89] to-[#185589] !overflow-x-hidden">
        <a
          href="https://tinyurl.com/rareprob-File-Manager"
          className="fixed z-50 bottom-10 lg:bottom-2 right-2 rounded-3xl px-7  drop-shadow-2xl
        py-3 text-white bg-gray-900 hover:scale-105 transition-all font-semibold"
        >
          Install App
        </a>

        <div className="flex flex-col md:flex-row items-center pt-20  text-white justify-center lg:space-x-32   ">
          <img
            loading={"lazy"}
            className="w-[300px] md:w-[400px] md:hidden"
            src="/images/fileManagerScreen/rare-fileMangerTopMobile-min.webp"
            alt="file manager"
          />

          <div>
            <h1 className="font-extrabold text-center text-4xl md:text-6xl -ml-14">
              File Manager
            </h1>
            <p className=" md:w-[400px] text-center text-xs md:text-lg px-10 md:px-0 font-medium md:text-left mt-6">
              {" "}
              File Manager is a free, fast, and flexible application that helps
              you to manage your files & data with no hassle. With the file
              management, Hider and cleaner functionality are like icing on the
              cake. File Manager is built with the free Callock feature that
              helps you to hide your private photos, videos, and other files &
              folders with password protection.
            </p>
            <p><a href="/privacy-policy" className="underline text-xs md:text-lg px-10 md:px-0 font-medium md:text-left">Privacy Policy</a></p>
            <a
              href="https://tinyurl.com/rareprob-File-Manager"
              target={"_blank"}
              rel="noreferrer"
            >
              <img
                src="/images/homepage/google.webp"
                alt="google"
                className="w-32 hover:scale-105 mx-auto md:ml-0 transition-all ease-linear   md:w-44 cursor-pointer "
              />
            </a>
            {/* <a href="https://play.google.com/store/apps/details?id=filemanager.files.fileexplorer.android.folder" target={"_blank"} rel="noreferrer">
             <img src="/images/homepage/Group 12.png" alt="google" className='w-32 border rounded-xl mx-auto md:ml-0  mt-10 p-2 hover:scale-105 transition-all ease-in-out  md:w-56 cursor-pointer '/>
           </a> */}
            {/* <a href="https://play.google.com/store/apps/details?id=filemanager.files.fileexplorer.android.folder" target={"_blank"} rel="noreferrer">
             <img src="/images/homepage/Group 12.png" alt="google" className='w-32 border mx-auto md:ml-0 rounded-xl p-2 mt-10 hover:scale-105 transition-all ease-in-out  md:w-44 cursor-pointer '/>
           </a> */}
          </div>

          <img
            loading={"lazy"}
            className="w-[400px] hidden md:block"
            src="/images/fileManagerScreen/rare-fileMangerTopMobile-min.webp"
            alt="file manager"
          />
        </div>

        {/* FEATURES */}

        <div className="bg-gray-200 h-[500px] md:h-[500px] mx-4 lg:mx-48  mt-20 rounded-3xl flex flex-col items-center justify-center">
          <h2 className="text-2xl md:text-3xl font-bold ">
            Meet the Prime Features
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-10 md:gap-36 mt-20">
            <div className="flex items-center justify-center">
              <img
                loading={"lazy"}
                src="/images/fileManagerScreen/file manager large file-138.svg"
                alt="Files Management"
                className="w-10 md:w-20"
              />
              <div>
                <h3 className="text-xs w-20 md:text-xl font-medium">
                  Files Management
                </h3>
              </div>
            </div>

            <div className="flex items-center  justify-center">
              <img
                loading={"lazy"}
                src="/images/fileManagerScreen/file manager sd card icon-138.svg"
                alt="SD Card Support"
                className="w-10 md:w-20"
              />
              <div>
                <h3 className="text-xs w-20 md:text-xl font-medium">
                  SD Card Support
                </h3>
              </div>
            </div>

            <div className="flex items-center justify-center">
              <img
                loading={"lazy"}
                src="/images/fileManagerScreen/file manager clean master-138.svg"
                alt="Clean Master"
                className="w-10 md:w-20"
              />
              <div>
                <h3 className="text-xs w-20 md:text-xl ml-1 font-medium">
                  Clean Master
                </h3>
              </div>
            </div>

            <div className="flex items-center justify-center">
              <img
                loading={"lazy"}
                src="/images/fileManagerScreen/file manager share on-138.svg"
                alt="Share & Receive Files"
                className="w-10 md:w-20"
              />
              <div>
                <h3 className="text-xs w-20 md:text-xl ml-1 font-medium">
                  Share & Receive Files
                </h3>
              </div>
            </div>

            <div className="flex items-center justify-center">
              <img
                loading={"lazy"}
                src="/images/fileManagerScreen/file manager downloader-138.svg"
                alt="Video Downloader"
                className="w-10 md:w-[74px]"
              />
              <div>
                <h3 className="text-xs w-20 md:text-xl ml-1 font-medium">
                  Video Downloader
                </h3>
              </div>
            </div>

            <div className="flex items-center justify-center">
              <img
                loading={"lazy"}
                src="/images/fileManagerScreen/file manager  status saver-138.svg"
                alt="Status Saver"
                className="w-10 md:w-20"
              />
              <div>
                <h3 className="text-xs w-20 md:text-xl ml-1 font-medium">
                  Status Saver
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center mt-20 justify-center md:space-x-32">
          <img
            loading={"lazy"}
            className="w-64 md:w-80"
            src="/images/fileManagerScreen/rare-fileManagerCallLockHider-min.webp"
            alt="Callock to hide  private Files"
          />
          <h2 className="text-2xl md:text-4xl w-96 text-center md:text-left  mt-10 font-bold text-white">
            Callock to hide <br /> private Files <br />
            <p className="text-xs md:text-xl mt-6 md:px-0 px-10">
              We know Privacy matters! File Manager is built with Callock
              functionality that lets you hide private photos and videos with no
              trouble. You can hide apps, music, photos, videos and other files
              with pin protection to maintain your private data
            </p>
            <a
              href="https://tinyurl.com/rareprob-File-Manager"
              target={"_blank"}
              rel="noreferrer"
            >
              <img
                src="/images/homepage/google.webp"
                alt="google"
                className="w-32 hover:scale-105 mx-auto md:ml-0 mt-4 transition-all ease-linear   md:w-56 cursor-pointer "
              />
            </a>
          </h2>
        </div>

        {/* SHORTCUT */}
        <div className="flex flex-col md:flex-row items-center justify-center md:space-x-24 mt-40">
          <h2 className="text-2xl md:text-4xl text-center md:text-left font-bold w-96 text-white">
            Prioritise favourite Folders as Shortcuts
            <p className="text-xs md:text-xl px-10 md:px-0 mt-6">
              File Manager is built with variety of features and one of the
              finest feature is "Shortcuts" functionality and this lets you put
              your favourite or mostly used folders as shortcuts. Shortcuts
              functionality helps to find your favourite folders easily at any
              time
            </p>
            <a
              href="https://tinyurl.com/rareprob-File-Manager"
              target={"_blank"}
              rel="noreferrer"
            >
              <img
                src="/images/homepage/google.webp"
                alt="google"
                className="w-32 hover:scale-105 mx-auto md:ml-0 mt-4 transition-all ease-linear   md:w-56 cursor-pointer "
              />
            </a>
          </h2>
          <img
            loading={"lazy"}
            className="w-44 mt-10 md:w-80"
            src="/images/fileManagerScreen/rare-fileManagerShortCut-min.webp"
            alt="Prioritise favourite Folders as Shortcuts"
          />
        </div>
        {/* Sd Card */}
        <div className="flex flex-col md:flex-row items-center justify-center md:space-x-24 bg-gray-200 mx-8 md:mx-20 rounded-3xl pt-10 mb-20 mt-20">
          <img
            loading={"lazy"}
            className="w-56 lg:w-96 "
            src="/images/fileManagerScreen/rare-fileManagerSdCard-min.webp"
            alt="SD Card "
          />
          <div className="text-black mt-10 pb-4 px-4">
            <h2 className="text-3xl md:text-6xl text-center md:text-right font-bold   lg:w-[500px]">
              SD Card
            </h2>
            <p className=" lg:w-[500px] px-4 mt-6 text-xs md:text-xl text-center md:text-right ">
              File Manager supports SD card functionality to manage your SD card
              photos, videos, music and other data. This lets you manage your
              android device data along with other files for the best possible
              app experience
            </p>
          </div>
        </div>
        <div className="h-44"></div>
        <NewFooter color="bg-black/10" />
      </main>
    </>
  );
};

export default FileManagerScreen;
